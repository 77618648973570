/**
 * Typography
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

@font-face {
	font-family: 'Open Sans';
	src:
		local('Open Sans'),
		local('OpenSans'),
		resolve('fonts/opensans-regular.woff2') format('woff2'),
		resolve('fonts/opensans-regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src:
		local('Open Sans Bold'),
		local('OpenSans-Bold'),
		resolve('fonts/opensans-bold.woff2') format('woff2'),
		resolve('fonts/opensans-bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src:
		local('Open Sans ExtraBold'),
		local('OpenSans-ExtraBold'),
		resolve('fonts/opensans-extrabold.woff2') format('woff2'),
		resolve('fonts/opensans-extrabold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}
