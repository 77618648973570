/**
 * Election Chart
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.election-chart {
	margin: 0 auto 2em;
}

.election-chart a::before {
	content: '';
	display: block;
	padding-block-start: 35%;
}

.election-chart a:is(:link, :visited, :hover, :focus, :active) {
	display: block;
	position: relative;
	max-inline-size: 600px;
	margin: 0 auto;
	overflow: hidden;
}

.election-chart img {
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
}
