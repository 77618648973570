/**
 * Teaser
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.teaser {
	position: relative;
	margin: 3em auto;
	padding: 20px 20px 5px;
	max-inline-size: 30em;
	color: var(--color-white);
	background: transparent;
}

.teaser::before {
	content: '';
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
	z-index: 1;
	inline-size: 100%;
	block-size: 100%;
	color: var(--color-white);
	background: var(--color-magenta);
	transform: skew(-2deg, -1deg);
	box-shadow: 10px 10px 0 var(--color-cyan);
}

.teaser > div {
	position: relative;
	z-index: 2;
}

.teaser h2 {
	display: inline-block;
	padding: 5px 10px;
	font-size: 1.5em;
	font-weight: 800;
	text-transform: uppercase;
	color: var(--color-cyan);
	background: var(--color-yellow);
	transform: skew(-2deg, -1deg);
}

.teaser a:is(:link, :visited, :hover, :focus, :active) {
	color: var(--color-white);
	background: transparent;
}

@media print {
	.teaser {
		margin: 0;
		padding: 0;
		max-inline-size: 100%;
		color: var(--color-black);
		background: transparent;
	}

	.teaser::before {
		display: none;
	}

	.teaser h2 {
		display: block;
		padding: 0;
		font-size: 1.25em;
		font-weight: bold;
		text-transform: none;
		color: var(--color-black);
		background: transparent;
		transform: none;
	}

	.teaser a:is(:link, :visited, :hover, :focus, :active) {
		color: var(--color-cyan);
		background: transparent;
	}
}
