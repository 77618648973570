/**
 * Main Navigation
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

nav ul {
	margin: 0;
	padding: 20px 0 0;
	list-style: none;
}

nav ul li {
	display: inline;
}

nav a:is(:link, :visited, :hover, :focus, :active) {
	display: block;
	position: relative;
	margin: 0 0 10px;
	padding: 0 20px 0 40px;
	font-size: 18px;
	font-weight: normal;
	text-decoration: none;
	white-space: nowrap;
	color: #222;
	background: transparent;
	-webkit-tap-highlight-color: rgb(255 255 255 / 35%);
}

nav a:is(:hover, :focus, :active)::after,
.home nav a[href='/']::after,
.staff nav a[href^='/vorstand']::after,
.council nav a[href^='/fraktion']::after,
.news nav a[href*='/aktuelles']::after,
.contact nav a[href^='/kontakt']::after,
.imprint nav a[href^='/impressum']::after,
.privacy nav a[href^='/impressum']::after,
.admin nav a[href*='/admin']::after {
	content: '';
	position: absolute;
	inset-block-start: 50%;
	inset-inline-start: 0;
	margin: -12px 0 0;
	border: 12px solid var(--color-white);
	border-block-color: transparent;
	border-inline-end: 0;
}

.home nav a[href='/']:is(:link, :visited, :hover, :focus, :active),
.staff nav a[href='/vorstand']:is(:link, :visited, :hover, :focus, :active),
.council nav a[href='/fraktion']:is(:link, :visited, :hover, :focus, :active),
.news nav a[href='/aktuelles']:is(:link, :visited, :hover, :focus, :active),
.contact nav a[href='/kontakt']:is(:link, :visited, :hover, :focus, :active),
.imprint nav a[href='/impressum']:is(:link, :visited, :hover, :focus, :active),
.privacy nav a[href='/impressum']:is(:link, :visited, :hover, :focus, :active),
.admin nav a[href='/admin']:is(:link, :visited, :hover, :focus, :active) {
	font-weight: bold;
}

@media print {
	nav {
		display: none;
	}
}
