/**
 * People
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.member-list {
	margin: 0 0 1em;
	padding: 0;
	list-style: none;
}

.member-list > li {
	position: relative;
	margin: 0 0 1em;
	padding: 0.5em 0 0.5em 100px;
	min-block-size: 115px;
}

.member-list h2,
.member-list h3 {
	font-size: 1.25em;
	color: var(--color-black);
	background: transparent;
}

.member-list h2 b,
.member-list h3 b {
	font-weight: bold;
}

.member-list h2 small,
.member-list h3 small {
	display: block;
	font-size: 0.8em;
	font-weight: normal;
	line-height: 1.5;
	color: #888;
	background: transparent;
}

.member-list img {
	position: absolute;
	inset-block-start: 0.4em;
	inset-inline-start: 0;
}

.member-list ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.member-list ul li {
	margin: 0.3333em 0 0;
	font-size: 0.75em;
}

.portrait {
	display: inline-block;
	margin: 0 1em 0 0;
	border-radius: 100%;
	max-inline-size: 85px;
	max-block-size: 85px;
	overflow: hidden;
	vertical-align: middle;
}

@media screen and (width >= 31em) {
	.member-list h2 b,
	.member-list h3 b {
		padding: 0 0.5ex 0 0;
	}

	.member-list h2 small,
	.member-list h3 small {
		display: inline;
		font-size: 1em;
	}

	.member-list ul li {
		margin: 0.25em 0 0;
		font-size: 1em;
	}
}

@media aural {
	.p-postal-code {
		speak-numeral: digits;
	}
}

@media print {
	.member-list > li {
		page-break-inside: avoid;
	}
}
