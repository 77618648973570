/**
 * News Article
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

article {
	display: block;
}

article aside {
	margin: 1em auto;
}

article figure {
	display: block;
	max-inline-size: 475px;
	margin: 1em auto;
	text-align: center;
}

article figure.left {
	float: left;
	max-inline-size: none;
	margin: 0 1em 1em 0;
	text-align: start;
}

article figure.right {
	float: right;
	max-inline-size: none;
	margin: 0 0 1em 1em;
	text-align: end;
}

article figcaption {
	display: block;
	inline-size: 100%;
	font-size: 0.75em;
}

article cite {
	font-style: normal;
}

article time,
article .byline,
article .source-org {
	display: none;
}

article .source {
	font-size: 0.75em;
}

article footer {
	margin: 0;
	padding: 0;
}
