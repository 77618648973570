/**
 * Boxes and Box Groups
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.box-group {
	margin: 2em 0 0;
}

.box {
	margin: 0 0 2em;
}

.box.box-signup,
.box.box-updated {
	margin: 0;
}

.box-headline {
	margin: 0;
	font-size: 22px;
	font-weight: 800;
	color: #111;
	background: transparent;
	text-transform: uppercase;
}

.box-content {
	position: relative;
	min-block-size: 2em;
}

.box ul {
	margin: 0 0 1em;
	padding: 0;
	list-style: none;
}

.box ul li {
	position: relative;
	padding: 0 0 0 16px;
}

.box ul li::before {
	content: '';
	position: absolute;
	inset-block-start: 7px;
	inset-inline-start: 0;
	inline-size: 0;
	block-size: 0;
	border-inline-start: 10px solid var(--color-gray);
	border-block: 5px solid transparent;
}

.box-more {
	color: var(--color-white);
	background: var(--color-gray);
	text-align: end;
}

.box-more a:is(:link, :visited, :hover, :focus, :active) {
	display: inline-block;
	padding: 0 10px;
	text-decoration: none;
}

.box-more a:is(:link, :visited) {
	color: var(--color-white);
	background: var(--color-cyan);
}

.box-more a:is(:hover, :focus, :active) {
	color: var(--color-white);
	background: var(--color-magenta);
}

.box-more .button:is(:link, :visited, :hover, :focus, :active) {
	display: block;
	line-height: 45px;
	text-align: center;
	box-sizing: border-box;
}

@media screen and (width >= 31em) {
	.box-content {
		padding: 5px 0 0;
		block-size: 130px;
		overflow: hidden;
	}

	.box.box-signup .box-content,
	.box.box-feed .box-content,
	.box.box-updated .box-content {
		block-size: auto;
	}

	.box-group {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		flex-wrap: wrap;
	}

	.box-group .box {
		flex: 1 1 47%;
		inline-size: auto;
	}

	.box-group .box:first-child {
		margin-inline-end: 3%;
	}

	.box-group .box.box-feed {
		flex: 1 1 100%;
		margin-inline-end: 0;
	}
}

@media screen and (width >= 64em) {
	.box.box-signup .box-content {
		block-size: 110px;
	}

	.box.box-updated .box-content {
		block-size: 130px;
	}

	.box-group .box {
		flex: 1 1 30%;
		margin: 0;
	}

	.box-group .box.box-signup {
		order: -1;
		margin-inline-end: 3%;
	}

	.box-group .box.box-updated {
		flex: 1 1 67%;
	}
}

@media print {
	.box {
		page-break-inside: avoid;
	}
}
