/**
 * Slogan
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.slogan {
	position: relative;
	margin: 0 auto 1em;
	max-inline-size: 420px;
	color: var(--color-text);
	background: transparent resolve('slogan.png') no-repeat center;
	background-size: 100%;
	font-size: 10px;
	text-indent: -1000em;
	direction: ltr;
	overflow: hidden;
}

.slogan::after {
	content: '';
	display: block;
	padding: 28.1% 0 0;
}

@media screen and (width >= 48em) {
	.slogan {
		max-inline-size: none;
	}
}

@media (resolution >= 192dpi) {
	.slogan {
		background-image: resolve('slogan@2x.png');
	}
}
