/**
 * Downloads
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.download-list {
	margin: 0 0 1em;
	padding: 0;
	list-style: none;
}

.download-list li {
	margin: 0.5em 0;
	padding: 0 0 0 25px;
}

.download-list .format-image,
.download-list .format-text {
	color: var(--color-text);
	background: transparent inline('../assets/document-text.png') no-repeat 0
		4px;
}

.download-list .format-pdf {
	color: var(--color-text);
	background: transparent inline('../assets/document-pdf.png') no-repeat 0 4px;
}

.collapsible-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.collapsible-list [role='tab'] a::before {
	content: '';
	position: absolute;
	inset-block-start: 7px;
	inset-inline-start: 0;
	inline-size: 0;
	block-size: 0;
	border-inline-start: 10px solid var(--color-gray);
	border-block: 5px solid transparent;
}

.collapsible-list [role='tab'] a:is(:link, :visited, :hover, :focus, :active) {
	display: block;
	position: relative;
	padding: 0 0 0 16px;
	color: var(--color-text);
	background: transparent;
	text-decoration: none;
}

.collapsible-list [role='tab'][data-open] a::before {
	transform: rotate(90deg);
}

.js .collapsible-list [role='tabpanel'][aria-expanded='false'] {
	display: none;
}
