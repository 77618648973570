/**
 * Base Layout
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

html {
	font: 87.5%/1.5 var(--font-stack);
	overflow-y: scroll; /* provides scrollbars to avoid jumping centered layouts */
	text-size-adjust: 100%; /* prevents text size adjust after orientation change */
}

html,
body {
	margin: 0;
	padding: 0;
	color: var(--color-text);
	background: var(--color-white);
}

main {
	display: block; /* correct `block` display not defined in IE 8/9/10/11 */
	position: relative;
	inset-block-end: -20px;
	margin: -20px 0 0;
	padding: 10px;
	color: var(--color-text);
	background: var(--color-white);
	box-shadow: 0 0 15px #999;
	transition: margin-top 0.2s ease;
}

h1 {
	margin: 7px 0 0.5em;
	font-size: 2em;
	line-height: 1.3;
	color: var(--color-black);
	background: transparent;
}

h1 span {
	display: none;
}

h2 {
	margin: 0 0 0.8em;
	font-size: 1.25em;
	line-height: 1.3;
	color: var(--color-black);
	background: transparent;
}

h3 {
	margin: 0 0 1em;
	font-size: 1em;
	line-height: 1.3;
	color: var(--color-text);
	background: transparent;
}

p {
	margin: 0 0 1em;
}

img {
	border: 0; /* removes border when inside `a` element in IE 8/9/10 */
	max-inline-size: 100%;
	block-size: auto;
	color: var(--color-text);
	background: transparent;
	vertical-align: bottom;
}

abbr[title] {
	border-block-end: none; /* remove the bottom border in Firefox 39- */
	text-decoration: none; /* remove any text decoration in Chrome, Edge, IE, Opera, and Safari */
}

table {
	margin: 0 0 1em;
	inline-size: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	font-size: 1em;
}

.wrapper {
	position: relative;
	color: var(--color-text);
	background: var(--color-gray);
}

.wrapper::before,
.wrapper::after {
	content: ' ';
	display: table;
}

.wrapper::after {
	clear: both;
}

.separator {
	margin: 2em 0;
	border-block-start: 5px solid var(--color-gray);
}

.separator hr {
	display: none;
}

[hidden] {
	display: none;
}

::selection {
	color: var(--color-white);
	background: var(--color-magenta);
	text-shadow: none;
}

@media screen and (width >= 22em) {
	main {
		margin: -40px 10px 0;
		min-block-size: 670px;
		transition: transform 0.2s ease;
		will-change: transform;
	}
}

@media screen and (width >= 31em) {
	h1 span {
		display: inline;
	}
}

@media screen and (width >= 48em) {
	html {
		font-size: 100%;
	}
}

@media screen and (width >= 48em) {
	main {
		float: left;
		margin-inline: 290px 20px;
		padding: 20px;
		inline-size: 674px;
		inline-size: calc(100% - 350px);
	}
}

@media screen and (width >= 64.1em) {
	.wrapper {
		margin: 30px auto 0;
		max-inline-size: 64em;
	}
}

@media print {
	html {
		font-size: 100%;
		orphans: 4;
		widows: 2;
	}

	html,
	body,
	.wrapper {
		color: var(--color-black);
		background: var(--color-white);
	}

	main {
		inset-block-end: 0;
		margin: 0;
		padding: 0;
		color: var(--color-black);
		background: transaprent;
		box-shadow: none;
	}

	h1,
	h2,
	h3 {
		page-break-after: avoid;
	}

	img {
		max-inline-size: 100%; /* prevents images from being cut when they are larger than page width */
	}
}
