/**
 * Variables (Custom Properties)
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

:root {
	/* fonts */
	--font-stack: 'Open Sans', calibri, sans-serif;

	/* colors */
	--color-text: #333;
	--color-black: #000;
	--color-white: #fff;
	--color-yellow: #ffed00;
	--color-magenta: #e5007d;
	--color-cyan: #009ee3;
	--color-turquoise: #00abad;
	--color-gray: #d7dde4;
	--color-red: #c00;
}
