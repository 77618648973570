/**
 * Sidebar
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.sidebar {
	position: absolute;
	inset-block-start: 130px;
	inset-inline-start: 0;
	block-size: 0;
	overflow: hidden;
	transition: height 0.2s ease;
	will-change: height;
}

.show-sidebar main {
	margin-block-start: 41em;
}

.show-sidebar main::before {
	content: '';
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
	inline-size: 100%;
	block-size: 100%;
}

.show-sidebar .sidebar {
	block-size: 42em;
}

@media screen and (width >= 22em) {
	.sidebar {
		inset-block-start: 200px;
		block-size: auto;
		transition: transform 0.2s ease;
		transform: translateX(-20em);
		will-change: transform;
	}

	.show-sidebar,
	.show-sidebar body {
		overflow-x: hidden;
		inline-size: 100%;
	}

	.show-sidebar .sidebar {
		block-size: auto;
		transform: translateX(0);
	}

	.show-sidebar main {
		margin-block-start: -40px;
	}

	.show-sidebar main,
	.show-sidebar footer {
		transform: translateX(20em);
	}
}

@media screen and (width >= 48em) {
	.sidebar {
		position: static;
		float: left;
		margin: 0 0 0 -100%;
		inline-size: 290px;
		transform: none;
	}

	.show-sidebar,
	.show-sidebar body {
		overflow-x: visible;
		inline-size: auto;
	}

	.show-sidebar main,
	.show-sidebar footer {
		transform: none;
	}
}
