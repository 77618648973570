/**
 * Links
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

a:is(:link, :visited, :hover, :focus, :active) {
	font-weight: bold;
	text-rendering: auto;
	-webkit-tap-highlight-color: rgb(229 0 125 / 25%);
}

a:is(:link, :visited) {
	color: var(--color-cyan);
	background: transparent;
	text-decoration: underline;
}

a:is(:hover, :focus, :active) {
	color: var(--color-magenta);
	background: transparent;
	text-decoration: none;
}
