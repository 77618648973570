/**
 * Masthead
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.wrapper > header {
	position: relative;
	padding: 20px 10px;
	color: var(--color-text);
	background: transparent;
}

/* logo */
.wrapper > header .logo {
	text-align: end;
}

.wrapper > header .logo + div {
	display: none;
}

.wrapper > header a:is(:link, :visited, :hover, :focus, :active) {
	display: inline-block;
	padding: 6px;
	color: var(--color-cyan);
	background: var(--color-yellow);
}

/* skiplink */
.wrapper > header .skiplink a::after {
	content: '...';
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
	inline-size: 100%;
	block-size: 100%;
	letter-spacing: 4px;
	font-size: 32px;
	text-indent: 0;
	text-align: center;
	transform: rotate(90deg) translate(3px, -5px);
}

.wrapper > header .skiplink a:is(:link, :visited, :hover, :focus, :active) {
	position: absolute;
	inset-block-start: 20px;
	inset-inline-start: 10px;
	inline-size: 57px;
	block-size: 57px;
	padding: 0;
	text-indent: -1000em;
	text-decoration: none;
	color: var(--color-magenta);
	background: #e7ebef;
	background: rgb(255 255 255 / 40%);
	overflow: hidden;
}

@media screen and (width >= 22em) {
	.wrapper > header {
		padding: 0;
		min-block-size: 165px;
		color: var(--color-text);
		background: transparent none no-repeat top;
		background-size: cover;
	}

	.wrapper > header.header-future {
		background-color: #9e9b87;
	}

	.no-webp .wrapper > header.header-future {
		background-image: resolve('header/perspektive.jpg');
	}

	.webp .wrapper > header.header-future {
		background-image: resolve('header/perspektive.webp');
	}

	.wrapper > header.header-taxes {
		background-color: #d7af68;
	}

	.no-webp .wrapper > header.header-taxes {
		background-image: resolve('header/steuern.jpg');
	}

	.webp .wrapper > header.header-taxes {
		background-image: resolve('header/steuern.webp');
	}

	.wrapper > header.header-labour {
		background-color: #8c8c86;
	}

	.no-webp .wrapper > header.header-labour {
		background-image: resolve('header/arbeit.jpg');
	}

	.webp .wrapper > header.header-labour {
		background-image: resolve('header/arbeit.webp');
	}

	.wrapper > header.header-knowledge {
		background-color: #aaafb7;
	}

	.no-webp .wrapper > header.header-knowledge {
		background-image: resolve('header/bildung.jpg');
	}

	.webp .wrapper > header.header-knowledge {
		background-image: resolve('header/bildung.webp');
	}

	.wrapper > header.header-people {
		background-color: #574445;
	}

	.no-webp .wrapper > header.header-people {
		background-image: resolve('header/buerger.jpg');
	}

	.webp .wrapper > header.header-people {
		background-image: resolve('header/buerger.webp');
	}

	.wrapper > header.header-bureaucracy {
		background-color: #9b9090;
	}

	.no-webp .wrapper > header.header-bureaucracy {
		background-image: resolve('header/buerokratie.jpg');
	}

	.webp .wrapper > header.header-bureaucracy {
		background-image: resolve('header/buerokratie.webp');
	}

	.wrapper > header::after {
		content: '';
		display: block;
		padding-block-start: 27.5%;
	}

	.wrapper > header .logo {
		position: absolute;
		inset-block-start: 20px;
		inset-inline-end: 10px;
	}

	.wrapper > header .skiplink a:is(:link, :visited, :hover, :focus, :active) {
		color: var(--color-white);
		background: var(--color-magenta);
	}
}

@media screen and (width >= 48em) {
	.wrapper > header {
		min-block-size: 1px;
		background-size: 100%;
	}

	.wrapper > header .logo {
		inset-block: auto 40px;
		inset-inline-end: 20px;
	}

	.wrapper > header .logo + div {
		display: block;
		position: absolute;
		inset-block-end: 43px;
		inset-inline-start: 20px;
		max-inline-size: 200px;
		font-size: 24px;
		font-weight: bold;
	}

	.wrapper > header .logo + div span {
		padding: 5px 10px;
		color: var(--color-white);
		background: var(--color-magenta);
	}

	.wrapper > header .skiplink a:is(:link, :visited, :hover, :focus, :active) {
		display: none;
	}
}

@media print {
	.wrapper > header {
		margin: 0 0 0.5cm;
		padding: 0;
	}

	.wrapper > header .skiplink a:is(:link, :visited, :hover, :focus, :active) {
		display: none;
	}
}
