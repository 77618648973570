/**
 * Backoffice
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

@keyframes flip {
	0% {
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		animation-timing-function: ease-in;
		opacity: 0%;
	}

	40% {
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		animation-timing-function: ease-in;
	}

	60% {
		transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
		opacity: 100%;
	}

	80% {
		transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	}

	100% {
		transform: perspective(400px);
	}
}

/* Item tables */
.admin table {
	table-layout: fixed;
}

.admin table td + td {
	padding: 0 0 0.5em 0.5em;
	inline-size: 3em;
	vertical-align: top;
}

.admin table td:first-child {
	padding: 0 0 0.5em;
	inline-size: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: top;
}

.admin code {
	font:
		1em consolas,
		monospace;
	color: var(--color-magenta);
	background: transparent;
}

/* Messages */
p[role='alert'] {
	padding: 0.5em 1em;
	font-weight: bold;
	color: var(--color-white);
	background: var(--color-turquoise);
	animation: flip 1s linear both;
	backface-visibility: visible;
}
