/**
 * Map
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

#map.not-initialized {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem;
	background-color: #e7ebef;
	text-align: center;
	box-sizing: border-box;
}

.map-toggle {
	display: flex;
	align-items: center;
	flex-grow: 1;
	margin-block-end: 1rem;
}

.map-disclaimer {
	max-block-size: 100%;
	overflow: auto;
	font-size: 0.75em;
}
