/**
 * Progress Bar
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}

.progress {
	position: absolute;
	inset-block-start: 50%;
	inset-inline-start: 50%;
	inline-size: 2em;
	block-size: 2em;
	margin: -1em 0 0 -1em;
	border: 2px solid var(--color-cyan);
	border-block-start-color: transparent;
	border-radius: 100%;
	box-sizing: border-box;
	animation: rotate 0.8s linear infinite;
}

.progress > * {
	display: none;
}
