/**
 * Sharing Links
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.sharing {
	display: flex;
	margin: 1em 0;
	padding: 0;
	text-align: center;
	align-items: stretch;
	list-style: none;
}

.sharing li {
	padding: 0 5px 0 0;
	inline-size: 25%;
	box-sizing: border-box;
}

.sharing svg {
	inline-size: 1em;
	block-size: 1em;
	fill: currentcolor;
	vertical-align: -0.125em;
}

.sharing a:is(:link, :visited, :hover, :focus, :active) {
	display: block;
	padding: 5px;
	font-size: 0.75em;
	font-weight: normal;
	text-decoration: none;
}

.sharing a[href*='facebook.com']:is(:link, :visited) {
	color: var(--color-white);
	background: #1778f2;
}

.sharing a[href*='facebook.com']:is(:hover, :focus, :active) {
	color: var(--color-white);
	background: #2f88f3;
}

.sharing a[href*='twitter.com']:is(:link, :visited) {
	color: var(--color-white);
	background: #55acee;
}

.sharing a[href*='twitter.com']:is(:hover, :focus, :active) {
	color: var(--color-white);
	background: #66baf0;
}

.sharing a[href^='whatsapp']:is(:link, :visited) {
	color: var(--color-white);
	background: #25d366;
}

.sharing a[href^='whatsapp']:is(:hover, :focus, :active) {
	color: var(--color-white);
	background: #3ad775;
}

.sharing a[href^='mailto']:is(:link, :visited) {
	color: var(--color-white);
	background: #666;
}

.sharing a[href^='mailto']:is(:hover, :focus, :active) {
	color: var(--color-white);
	background: #777;
}

@media screen and (width >= 31em) {
	.sharing a:is(:link, :visited, :hover, :focus, :active) {
		font-size: 1em;
	}
}

@media screen and (width >= 64em) {
	.sharing i {
		display: inline-block;
		margin: 0 3px 0 0;
		font-size: 1em;
	}
}
