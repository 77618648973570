/**
 * Calendar
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.calendar thead {
	display: none;
}

.calendar .calendar-date {
	padding: 0 10px 0 0;
	border-block-start: 1.5em solid transparent;
	inline-size: 3.125em;
}

.calendar .calendar-date time {
	display: inline-block;
	border-block-start: 0.5em solid var(--color-yellow);
	padding: 0.25em 0 0;
	inline-size: 3.125em;
	block-size: 4.0625em;
	color: var(--color-white);
	background: var(--color-cyan);
	font-weight: bold;
	line-height: 1;
	text-align: center;
	vertical-align: middle;
	box-sizing: border-box;
}

.calendar .calendar-date span {
	display: block;
	margin: 0 0 2px;
	text-align: center;
	font-size: 1.75em;
	font-weight: bold;
}

.calendar .calendar-item {
	padding: 0;
	border-block-start: 1.5em solid transparent;
	min-block-size: 4.0625em;
	line-height: 1.3;
}

.calendar .calendar-item .p-summary {
	font-size: 0.8em;
	line-height: normal;
}

.calendar .calendar-item .u-url {
	display: none;
	visibility: hidden;
}

.calendar tr:first-child .calendar-date,
.calendar tr:first-child .calendar-item {
	border-block-start: 0;
}

@media print {
	.calendar tr {
		page-break-inside: avoid;
	}
}
