/**
 * Buttons
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* Form buttons */
button {
	display: inline-block;
	margin: 0;
	padding: 0 1em;
	border: 0;
	block-size: 3.2143em;
	overflow: visible; /* show the overflow in IE */
	font: bold 1em/3.2143 var(--font-stack);
	text-align: center;
	text-transform: none; /* remove the inheritance of text-transform in Edge, Firefox, and IE */
	vertical-align: bottom;
	box-sizing: border-box;
	color: var(--color-white);
	background: var(--color-cyan);
	cursor: pointer;
	appearance: none;
}

button:is(:hover, :focus, :active) {
	color: var(--color-white);
	background: var(--color-magenta);
	outline: 0;
}

/* Link buttons */
.button:is(:link, :visited, :hover, :focus, :active) {
	display: inline-block;
	padding: 0 1em;
	block-size: 3.2143em;
	font: bold 1em/3.2143 var(--font-stack);
	text-align: center;
	text-decoration: none;
}

.button:is(:link, :visited) {
	color: var(--color-white);
	background: var(--color-cyan);
}

.button:is(:hover, :focus, :active) {
	color: var(--color-white);
	background: var(--color-magenta);
}

/* Signup buttons */
.button-signup::before {
	content: '';
	display: inline-block;
	margin: 0 5px 0 0;
	inline-size: 30px;
	block-size: 22px;
	vertical-align: -5px;
	color: var(--color-white);
	background: transparent inline('../assets/mitglied-werden.png') no-repeat
		center;
	background-size: 30px 22px;
}

/* Feed buttons */
.button-feed::before {
	content: '';
	display: inline-block;
	margin: 0 5px 0 0;
	inline-size: 21px;
	block-size: 22px;
	vertical-align: -5px;
	color: var(--color-white);
	background: transparent inline('../assets/feed.png') no-repeat center;
	background-size: 21px 22px;
}

/* Edit and delete buttons */
.button-edit:is(:link, :visited, :hover, :focus, :active) {
	block-size: 1.5em;
	line-height: 1.5;
}

.button-delete {
	block-size: 1.5em;
	line-height: 1.5;
	color: var(--color-white);
	background: var(--color-red);
}

.button-delete:is(:hover, :focus, :active) {
	block-size: 1.5em;
	line-height: 1.5;
	color: var(--color-white);
	background: #e00;
}

/* Cancel buttons */
.button-cancel:is(:link, :visited) {
	color: var(--color-white);
	background: #ccc;
}

.button-cancel:is(:hover, :focus, :active) {
	color: var(--color-white);
	background: #aaa;
}

/* Disabled link buttons */
.button[aria-disabled='true']:is(:link, :visited, :hover, :focus, :active) {
	color: var(--color-white);
	background: #aaa;
	cursor: not-allowed;
}

/* Buttons with icons */
.button svg {
	inline-size: 1em;
	block-size: 1em;
	fill: currentcolor;
	vertical-align: -0.125em;
}

@media screen and (width >= 48em) {
	button,
	.button:is(:link, :visited, :hover, :focus, :active) {
		&:not(.button-edit, .button-delete) {
			block-size: 2.8125em;
			line-height: 2.8125em;
		}
	}
}
