/**
 * Lightbox
 * @project FDP Heinsberg
 * @author  Lhokesh Dhakar <http://lokeshdhakar.com/projects/lightbox2/>
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

/* stylelint-disable selector-class-pattern -- Lightbox2 library code */

@charset 'utf-8';

body.lb-disable-scrolling {
	overflow: hidden;
}

.lightboxOverlay {
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
	z-index: 9999;
	background-color: var(--color-black);
	opacity: 80%;
	display: none;
}

.lightbox {
	position: absolute;
	inset-inline-start: 0;
	inline-size: 100%;
	z-index: 10000;
	text-align: center;
	line-height: 0;
	font-weight: normal;
}

.lightbox .lb-image {
	display: block;
	block-size: auto;
	max-inline-size: inherit;
	max-block-size: none;
	border-radius: 3px;

	/* Image border */
	border: 4px solid var(--color-white);
}

.lightbox a img {
	border: none;
}

.lb-outerContainer {
	position: relative;
	inline-size: 250px;
	block-size: 250px;
	margin: 0 auto;
	border-radius: 4px;

	/* Background color behind image. This is visible during transitions. */
	background-color: var(--color-white);
}

.lb-outerContainer::after {
	content: '';
	display: table;
	clear: both;
}

.lb-loader {
	position: absolute;
	inset-block-start: 43%;
	inset-inline-start: 0;
	block-size: 25%;
	inline-size: 100%;
	text-align: center;
	line-height: 0;
}

.lb-cancel {
	display: block;
	inline-size: 32px;
	block-size: 32px;
	margin: 0 auto;
	background: inline('../assets/lightbox/loading.gif') no-repeat;
}

.lb-nav {
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
	block-size: 100%;
	inline-size: 100%;
	z-index: 10;
}

.lb-container > .nav {
	inset-inline-start: 0;
}

.lb-nav a {
	outline: none;
}

.lb-prev,
.lb-next {
	block-size: 100%;
	cursor: pointer;
	display: block;
}

.lb-nav a.lb-prev {
	inline-size: 34%;
	inset-inline-start: 0;
	float: left;
	background: inline('../assets/lightbox/prev.png') left 48% no-repeat;
	opacity: 0%;
	transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
	opacity: 100%;
}

.lb-nav a.lb-next {
	inline-size: 64%;
	inset-inline-end: 0;
	float: right;
	background: inline('../assets/lightbox/next.png') right 48% no-repeat;
	opacity: 0%;
	transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
	opacity: 100%;
}

.lb-dataContainer {
	margin: 0 auto;
	padding-block-start: 5px;
	inline-size: 100%;
	border-end-start-radius: 4px;
	border-end-end-radius: 4px;
}

.lb-dataContainer::after {
	content: '';
	display: table;
	clear: both;
}

.lb-data {
	padding: 0 4px;
	color: var(--color-white);
}

.lb-data .lb-details {
	inline-size: 85%;
	float: left;
	text-align: start;
	line-height: 1.1em;
}

.lb-data .lb-caption {
	font-size: 13px;
	font-weight: bold;
	line-height: 1em;
}

.lb-data .lb-caption a {
	color: var(--color-cyan);
}

.lb-data .lb-number {
	display: block;
	clear: inline-start;
	padding-block-end: 1em;
	font-size: 12px;
	color: var(--color-gray);
}

.lb-data .lb-close {
	display: block;
	float: right;
	inline-size: 30px;
	block-size: 30px;
	background: inline('../assets/lightbox/close.png') top right no-repeat;
	text-align: end;
	outline: none;
	opacity: 70%;
	transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
	cursor: pointer;
	opacity: 100%;
}
