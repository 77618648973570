/**
 * Crosslinking
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

aside ul {
	margin: 40px 0 0;
	padding: 0;
	list-style: none;
}

aside ul li {
	display: inline;
}

aside a::before {
	content: '';
	position: absolute;
	inset-block-start: 1px;
	inset-inline-start: 0;
	inline-size: 16px;
	block-size: 16px;
	color: #222;
	background: transparent inline('../assets/crosslink.png') no-repeat 0 0;
	background-size: 16px 48px;
}

aside a[href*='fdp']::before {
	background-position: 0 -16px;
}

aside a[href*='julis']::before {
	background-position: 0 -32px;
}

aside a:is(:link, :visited, :hover, :focus, :active) {
	display: block;
	position: relative;
	margin: 0 0 5px 40px;
	padding: 0 20px 0 25px;
	font-size: 13px;
	font-weight: normal;
	text-decoration: none;
	color: #222;
	background: transparent;
}

@media print {
	aside {
		display: none;
	}
}
