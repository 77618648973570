/**
 * Footer
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

footer {
	display: block; /* correct `block` display not defined in IE 8/9 */
	margin: 30px auto;
	text-align: center;
	color: #999;
	background: transparent;
	clear: both; /* footers should clear all content floats */
}

footer p {
	margin: 0;
	font-size: 0.8125em;
}

@media screen and (width >= 22em) {
	footer {
		transition: transform 0.2s ease;
		transform: translateX(0);
		will-change: transform;
	}
}

@media screen and (width >= 48em) {
	footer {
		padding: 0 0 0 290px;
		max-inline-size: 710px;
	}
}

@media print {
	footer {
		color: var(--color-black);
		background: transparent;
	}
}
