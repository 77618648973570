/**
 * Form
 * @project FDP Heinsberg
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

form {
	margin: 0 0 2em;
}

fieldset {
	margin: 0;
	padding: 0;
	border: 0;
}

legend {
	display: none;
}

label {
	display: block;
	font-weight: bold;
	cursor: pointer;
}

input,
output,
textarea {
	inline-size: 100%;
	margin: 0;
	padding: 0.5em;
	border: 2px solid var(--color-gray);
	border-radius: 0;
	font: 1.1429em/1.5 var(--font-stack);
	vertical-align: bottom;
	color: var(--color-text);
	background: var(--color-white);
	box-sizing: border-box;
}

textarea {
	overflow: auto; /* remove the default vertical scrollbar in IE */
}

input:focus,
output:focus,
textarea:focus {
	border-color: var(--color-magenta);
	outline: 0;
}

select {
	font-size: 1.1429em;
	cursor: pointer;
}

input[type='date']::-webkit-inner-spin-button,
input[type='time']::-webkit-inner-spin-button {
	margin: 0;
	appearance: none;
}

input[type='file'] {
	padding: 0;
	border: 0;
	line-height: 1;
	cursor: pointer;
}

[aria-invalid='true'] {
	border-color: var(--color-red);
}

.error {
	display: block;
	margin-block-start: 0.25em;
	font-size: 0.75em;
	color: var(--color-red);
	background: transparent;
}

.hint {
	display: block;
	margin-block-start: 0.25em;
	font-size: 0.75em;
	color: #888;
	background: transparent;
}

.dropdown {
	display: inline-block;
	position: relative;
	inline-size: 100%;
}

.dropdown::after {
	content: '';
	position: absolute;
	inset-block-end: 20px;
	inset-inline-end: 15px;
	inline-size: 10px;
	block-size: 10px;
	border: 2px solid var(--color-white);
	border-block-start: 0;
	border-inline-end: 0;
	transform: rotate(-45deg);
	pointer-events: none;
}

.dropdown select {
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
	z-index: 1;
	inline-size: 100%;
	block-size: 100%;
	opacity: 0%;
}

.dropdown output {
	display: block;
	padding-inline-end: 46px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	max-inline-size: calc(100vw - 20px);
}

.dropdown output::after {
	content: '';
	position: absolute;
	inset-block: 2px;
	inset-inline-end: 2px;
	inline-size: 40px;
	color: var(--color-white);
	background: var(--color-gray);
	pointer-events: none;
}

.dropdown select:focus + output {
	border-color: var(--color-magenta);
	outline: 0;
}

.dropdown select:focus + output::after {
	color: var(--color-white);
	background: var(--color-magenta);
}

.uploaded:not(:empty) {
	display: block;
	margin-block-end: 0.5rem;
}

.delete-form {
	display: inline;
}

@media screen and (width >= 22em) {
	.dropdown output {
		max-inline-size: calc(100vw - 40px);
	}
}

@media screen and (width >= 48em) {
	input,
	output,
	textarea,
	select {
		font-size: 1em;
	}
}
